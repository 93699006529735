.active-box {
}

.inactive-box {
	color: #a6a6a6;
	font-style: italic;
}

.modal {
	display: block;
}

.qp-rule-text {
	line-height: 1.5;
}

.qp-bg {
	background-color: #f0f3f5;
}

.qp-word-wrap {
	white-space: normal !important;
}

.qp-body-container {
	min-height: calc(100vh - 225px);
}

.qp-no-margin-right {
	margin-right: 0px;
}

.qp-block {
	display: block;
}

.qp-table {
	width: 100%;
	display: table-row;
}

.qp-left {
	width: 85px;
	float: left;
	display: table-cell;
}

.qp-right {
	width: 165px;
	float: right;
	display: table-cell;
}

.qp-middle {
	width: 100%;
	display: table-cell;
	vertical-align: middle;
}

.qp-margin-bottom {
	margin-bottom: 20px;
}

hr {
	margin-top: 0px;
}

.qp-non-tab-content,
.qp-tab-content {
	padding: 20px;
	background-color: #fff;
	margin-bottom: 10px;
}

.qp-tab-content {
	margin-top: -30px;
}

.spinner {
	display: inline;
}

td .qp-inline-btn {
	margin-top: -0px;
}

.qp-pointer {
	cursor: pointer;
}

.qp-inline-btn {
	margin-top: -5px;
	font-size: 22px;
	padding: 0px;
	padding-left: 7px;
	padding-right: 7px;
}

.qp-inline-btn:hover[disabled],
.qp-inline-btn[disabled] {
	background-color: transparent !important;
	border: 1px solid transparent !important;
	color: gray !important;
}

.qp-inline-btn:hover {
	color: #000000 !important;
	background-color: transparent !important;
	border: 1px solid transparent !important;
}

.qp-text-field-message {
	margin-top: -10px;
	padding-bottom: 20px;
}

.qp-radio-group {
	border: 1px solid #35ad96;
	background-color: #fff;
	height: 50px;
}

.qp-info-sign {
	line-height: 50px;
	font-size: 30px;
	cursor: help;
}

.qp-info-sign img {
	margin-top: -5px;
	margin-left: 5px;
}

/* BEGIN hacks for MCP UX styles */
.btn-block {
	text-align: left;
}

.table {
	background-color: #ffffff;
}

.table tr > td:first-child,
.table tr > th:first-child {
	padding-left: 10px;
}

.table thead tr > td,
.table thead tr > th {
	padding: 5px 5px 5px 15px;
}

.qp-form-control {
	height: inherit;
}

.btn-group > .btn-sm {
	padding: 4px 10px;
}

.btn i {
	padding-right: 0px !important;
}

.input-group .form-control:focus {
	z-index: inherit;
}

.checkbox {
	margin-top: 1px !important;
	margin-bottom: 5px !important;
	margin-left: 10px;
}

.form-group label {
	top: 5px;
	font-size: 12px;
}

.form-group input {
	padding: 23px 16px 6px !important;
}

.form-group.has-success:after {
	top: 26px !important;
}

.form-group.has-error:after {
	-webkit-transition-duration: 0ms !important;
	-moz-transition-duration: 0ms !important;
	-ms-transition-duration: 0ms !important;
	-o-transition-duration: 0ms !important;
	transition-duration: 0ms !important;
	top: 26px;
}

.form-group.has-success:after {
	-webkit-transition-duration: 0ms !important;
	-moz-transition-duration: 0ms !important;
	-ms-transition-duration: 0ms !important;
	-o-transition-duration: 0ms !important;
	transition-duration: 0ms !important;
	top: 26px;
}

input {
	-webkit-transition-duration: 0ms !important;
	-moz-transition-duration: 0ms !important;
	-ms-transition-duration: 0ms !important;
	-o-transition-duration: 0ms !important;
	transition-duration: 0ms !important;
}

/* END hacks for MCP UX styles */

/* BEGIN Hacks for react-select (to MCP-fy it) */
.Select-control {
	background-color: transparent !important;
	z-index: 1000 !important;
	border: 0px solid transparent;
	height: auto;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	overflow: auto;
}

/* END Hacks for react-select (to MCP-fy it) */

.react-datepicker__input-container .form-control {
	height: inherit;
}

.input-group .form-control {
	height: inherit;
}

#locations-list-inline-search-wrapper .form-group {
	margin-bottom: inherit;
}

/* react-bootstrap-table + MCP Styles */

.qp-bs-table-row {
	margin-left: -15px;
	margin-right: -15px;
}

.react-bs-table-tool-bar .form-control {
	height: inherit;
}

.react-bs-table-search-form .form-control {
	font-size: larger;
	padding: 6px 16px 6px !important;
}

.react-bs-table {
	border: inherit !important;
	border-radius: inherit !important;
}

.qp-dimension-field:after {
	font-family: FontAwesome;
	position: absolute;
	content: '\f00d';
	right: -8px;
	top: 13px;
	color: rgb(196, 205, 214);
	transition: all 0.2s ease-out;
	font-size: 20px;
}

.no-break {
	white-space: pre;
}
